<template>
  <div id="mrf-report">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-dialog v-model="AddRecordPrompt" persistent>
        <designation-create
          :AddRecordPrompt="AddRecordPrompt"
          @hideDialog="hideAddRecordPrompt"
          v-if="AddRecordPrompt"
        ></designation-create>
      </v-dialog>

      <v-dialog v-model="EditRecordPrompt" persistent>
        <designation-edit
          :EditRecordPrompt="EditRecordPrompt"
          :recordData="selectedData"
          @hideDialog="hideEditRecordPrompt"
          v-if="EditRecordPrompt"
        ></designation-edit>
      </v-dialog>

      <v-dialog v-model="RearrangeRecordPrompt" persistent>
        <designation-rearrange
          :RearrangeRecordPrompt="RearrangeRecordPrompt"
          @hideDialog="hideRearrangeRecordPrompt"
          v-if="RearrangeRecordPrompt"
        ></designation-rearrange>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              v-if="tableOptions1.CreateButtonFlag"
              @click.prevent="AddRecordPrompt = true"
              color="success"
              elevation="30"
              shaped
              tile
              large
              class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
            >Create</v-btn>
            <v-btn
              v-if="tableOptions1.RearrangeButtonFlag"
              @click.prevent="RearrangeRecordPrompt = true"
              color="primary"
              elevation="30"
              shaped
              tile
              large
              class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
            >Rearrange</v-btn>
          </v-col>
        </v-row>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Designation Type
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="DesignationType"
                  :rules="DesignationTypeRules"
                  :items="DesignationTypeOptions"
                  :loading="DesignationTypeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Search</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>{{ tableData1.length }} Records found.</h4>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                  >{{ item.ActiveStatusTxt }}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-document-edit</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-delete</v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import DesignationCreate from "@/view/pages/erp/designation/DesignationCreate.vue";
import DesignationEdit from "@/view/pages/erp/designation/DesignationEdit.vue";
import DesignationRearrange from "@/view/pages/erp/designation/DesignationRearrange.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: { DesignationCreate, DesignationEdit, DesignationRearrange },
  data() {
    return {
      LoadingFlag: false,
      SearchFlag: false,
      uploadPercentage: 0,
      AddRecordPrompt: false,
      EditRecordPrompt: false,
      RearrangeRecordPrompt: false,

      PageTitle: "",
      PageDescription: "",

      selected: {},
      selectedData: {},

      valid1: true,
      OverlayLoadingFlag: false,

      JciYearCode: "",

      DesignationTypeRules: [v => !!v || "Designation Type is required"],
      DesignationType: "",
      DesignationTypeOptions: [],
      DesignationTypeOptionsLoading: false,

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getDesignationTypeOptions();
        this.getTableRecords();
      }
    },
    DesignationTypeOptions: function() {
      console.log("watch DesignationTypeOptions");
      this.DesignationTypeOptionsLoading = false;
    }
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "designation",
        Action: "list"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.getTableRecords();
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.toast("error", message);
      }
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData1 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/designation/lists";
      var upload = {
        UserInterface: 1,
        DesignationType: this.DesignationType
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          console.log({ records });

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions1 = options;

          if (flag == 1) {
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableData1 = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
    getDesignationTypeOptions() {
      console.log("getDesignationTypeOptions called");
      this.DesignationTypeOptionsLoading = true;
      this.DesignationType = "";
      var selectbox1_source = "DesignationType";
      var selectbox1_destination = "DesignationTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        CategoryMainName: 'designation_type'
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.AddRecordPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    hideRearrangeRecordPrompt(flag) {
      console.log("hideRearrangeRecordPrompt called");
      this.RearrangeRecordPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.EditRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one city to edit";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.EditRecordPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Designation Name <b> " + tr.DesignationName + "</b>";
        htmlTxt +=
          "<br/> Organization Name <b> " + tr.OrganizationTypeName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6"
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(data) {
      console.log("deleteData is called");

      var DesignationCode = data.DesignationId;
      var OrganizationType = data.OrganizationTypeId;
      var Portfolio = data.PortfolioId;

      console.log(
        "DesignationCode=" +
          DesignationCode +
          "; OrganizationType=" +
          OrganizationType +
          "; Portfolio=" +
          Portfolio
      );

      if (DesignationCode > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/designation/destroy";
        var upload = {
          UserInterface: 1,
          Designation: DesignationCode,
          OrganizationType: OrganizationType,
          Portfolio: Portfolio
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Designation should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#mrf-report {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>